import axios from 'axios'

axios.defaults.withCredentials = true

export default {
  getReleases: function (params) {
    return axios.get(`${window.apiURL}api/releases`, { params })
  },
  getArtist: function (key) {
    return axios.get(`${window.apiURL}api/artists/${key}`)
  },
  getArtistReleases: function (artistId) {
    return axios.get(`${window.apiURL}api/releases?artist=${artistId}`)
  },
  getFeaturedArtists: function () {
    return axios.get(`${window.apiURL}api/artists?featured=1`)
  },
  getArtists: function (params) {
    return axios.get(`${window.apiURL}api/artists`, { params })
  },
  getSmartlink: function (key) {
    return axios.get(`${window.apiURL}api/smartlinks/${key}`)
  },
  getLyrics: function (params) {
    return axios.get(`${window.apiURL}api/lyrics`, { params })
  },
  getLyric: function (id) {
    return axios.get(`${window.apiURL}api/lyrics/${id}`)
  }
}
