<template>
  <div>
    <div class="mb-3 text-center">
      <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-8326584585992829"
        data-ad-slot="3714619695"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>

    <div class="mb-4">
      <router-link :to="`/genres`" class="btn btn-warning" v-if="genre">
        <b>Genre:</b> {{ genre }} <span class="fa fa-times ml-1"></span>
      </router-link>
      <router-link :to="`/genres/${genre}`" class="btn btn-warning ml-2" v-if="style">
        <b>Style:</b> {{ style }} <span class="fa fa-times ml-1"></span>
      </router-link>
    </div>

    <div class="iq-card" v-if="!genre">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Genres</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <ul class="list-unstyled row iq-box-hover mb-0">
          <li class="col-xl-2 col-lg-3 col-md-4 iq-music-box" v-for="(genre, index) in genres" v-bind:key="`genre_${index}`">
            <div class="iq-card">
              <div class="iq-card-body p-0">
                <div class="iq-thumb">
                  <div class="iq-music-overlay"></div>
                  <router-link :to="`/genres/${genre.replace('/', '-')}`">
                    <img :src="getGenreImage(genre) || defaultImage" class="img-border-radius img-fluid w-100" alt="">
                  </router-link>
                  <div class="overlay-music-icon">
                    <router-link :to="`/genres/${genre.replace('/', '-')}`">
                      <i class="las la-eye"></i>
                    </router-link>
                  </div>
                </div>
                <div class="feature-list text-center">
                  <h6 class="font-weight-600 mb-0">
                    <router-link :to="`/genres/${genre.replace('/', '-')}`">
                      {{ genre }}
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="iq-card" v-if="genre && !style">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">{{ genre }} Styles</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <ul class="list-unstyled row iq-box-hover mb-0">
          <li class="col-xl-2 col-lg-3 col-md-4 iq-music-box" v-for="(style, index) in styles" v-bind:key="`style_${index}`">
            <div class="iq-card">
              <div class="iq-card-body p-0">
                <div class="iq-thumb">
                  <div class="iq-music-overlay"></div>
                  <router-link :to="`/genres/${genre.replace('/', '-')}/${style.replace('/', '-')}`">
                    <img :src="defaultImage" class="img-border-radius img-fluid w-100" alt="">
                  </router-link>
                  <div class="overlay-music-icon">
                    <router-link :to="`/genres/${genre.replace('/', '-')}/${style.replace('/', '-')}`">
                      <i class="las la-eye"></i>
                    </router-link>
                  </div>
                </div>
                <div class="feature-list text-center">
                  <h6 class="font-weight-600 mb-0">
                    <router-link :to="`/genres/${genre.replace('/', '-')}/${style.replace('/', '-')}`">
                      {{ style }}
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="iq-card" v-if="genre && style">
      <div class="iq-card-header d-flex justify-content-between align-items-center">
        <div class="iq-header-title">
          <h1 class="card-title h4">{{ $t('showing') }} {{ totalReleases }} <span class="text-primary">{{ genre }}</span> <span v-if="style && style !== 'All Styles'">/</span> <span class="text-primary" v-if="style !== 'All Styles'">{{ style }}</span> Releases</h1>
        </div>
        <div id="feature-album-slick-arrow" class="slick-aerrow-block">
          <router-link :to="`/genres/${genre.replace('/', '-')}/${style.replace('/', '-')}?page=${currentPage - 1}`" class="btn btn-primary" :disabled="currentPage <= 1">
            <i class="fa fa-chevron-left"></i>
          </router-link>
          <a href="javascript:void(0)" class="btn btn-default">Page {{ currentPage }} of {{ getTotalPages() }}</a>
          <router-link :to="`/genres/${genre.replace('/', '-')}/${style.replace('/', '-')}?page=${currentPage + 1}`"  class="btn btn-primary" :disabled="currentPage >= getTotalPages()">
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </div>
      </div>
      <div class="iq-card-body">
        <p class="text-center p-3" v-if="loading">
          <i class="fa fa-spinner fa-spin"></i>
        </p>
        <ul class="list-unstyled row iq-box-hover mb-0" v-if="!loading">
          <li class="col-xl-3 col-lg-3 col-md-4 iq-music-box" v-for="(release, index) in releases" v-bind:key="`artists_${index}`">
            <div class="iq-card">
              <div class="iq-card-body p-0">
                <div class="iq-thumb">
                  <div class="iq-music-overlay"></div>
                  <router-link :to="`/releases/${release.key}`">
                    <img onerror="this.src = '/assets/img/img-placeholder.jpg'" :src="release.cover_image_small || release.cover_image || defaultImage" class="img-border-radius img-fluid w-100" alt="">
                  </router-link>
                  <div class="overlay-music-icon">
                    <router-link :to="`/releases/${release.key}`">
                      <i class="las la-eye"></i>
                    </router-link>
                  </div>
                </div>
                <div class="feature-list text-center">
                  <h6 class="font-weight-600 mb-0">
                    <router-link :to="`/releases/${release.key}`">
                      {{ release.title }}
                    </router-link>
                  </h6>
                  <p class="mb-0">
                    <img class="country-flag mr-2" :src="`/assets/img/countries/${release.user.country || 'default'}.png`" onerror="this.src = '/assets/img/countries/default.png'" :alt="getCountryName(release.user.country)" :title="getCountryName(release.user.country)" width="16">
                    <router-link :to="`/artist/${release.artist.key}`">{{ release.artist.name }}</router-link>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import genres from '../data/genres';
import _ from 'underscore';
import Music from '../services/music';
import countries from '../data/countries';
import events from '../utils/events';

export default {
  name: 'GenresPage',
  data: function () {
    return {
      genreImages:{
        'Alternative': 'alternative.png',
        'Blues': 'blues.png',
        'Children\'s Music': 'child.png',
        'Country': 'country.png',
        'Classical': 'classical.png',
        'Comedy': 'comedy.png',
        'Dance': 'dance.png',
        'Electronic': 'electronic.png',
        'Folk': 'folk.png',
        'Holiday': 'holiday.png',
        'Hip Hop/Rap': 'hiphop.png',
        'Latin': 'latin.png',
        'Jazz': 'jazz.png',
        'Inspirational': 'inspirational.png',
        'Pop': 'pop.png',
        'New Age': 'newage.png',
        'R&B/Soul': 'rb.png',
        'Reggae': 'reggae.png',
        'Rock': 'rock.png',
        'Spoken Word': 'spoken.png',
        'Vocal': 'vocal.png',
        'World': 'world.png'
      },
      loading: false,
      genre: '',
      defaultImage: '/assets/img/img-placeholder.jpg',
      genres: _.map(genres, (item, index) => index),
      styles: [],
      releases: [],
      totalReleases: 0,
      searchLimit: 50,
      currentPage: 1,
      results: 0,
      search: '',
    }
  },
  methods: {
    getGenreImage: function (genre) {
      if (this.genreImages[genre]) {
        return `/assets/img/genres/${this.genreImages[genre]}`;
      } else {
        return '';
      }
    },
    getCountryName: function (userCountry) {
      let country = _.find(countries, item => item.code === userCountry)

      if (country) {
        return country.name;
      } else {
        return userCountry || 'N/A';
      }
    },
    getTotalPages: function () {
      return Math.ceil(this.totalReleases / this.searchLimit) || '1';
    },
    getReleases: function () {
      let style = this.style.replace('-', '/');
      this.loading = true;

      if (style === 'All Styles') {
        style = '';
      }

      if (this.currentPage === 1) {
        this.results = 0;
        this.totalReleases = 0;
      }

      this.releases = [];

      Music.getReleases({ limit: this.searchLimit, page: this.currentPage - 1, orderBy: 'release_date', main_genre: this.genre.replace('-', '/'), style }).then(response => {
        this.releases = response.data.items;
        this.results = response.data.results;
        this.totalReleases = response.data.total;
        this.loading = false;

        setTimeout(() => {
          this.prerenderReady = true;
        }, 1000);
      });
    },
    initParams: function () {
      this.genre = this.$route.params.genre;
      this.style = this.$route.params.style;
      this.currentPage = parseInt(this.$route.query.page || 1);

      if (this.genre) {
        this.styles = _.filter(genres[this.genre.replace('-', '/')], item => item);
      } else {
        this.styles = [];
      }

      if (this.genre && this.style) {
        this.getReleases()
      }
    }
  },
  mounted: function () {
    let description = 'Explore our extensive catalog featuring a wide range of genres, including indie rock, electronic, hip-hop, jazz, folk, alternative, pop, and more. Discover your favorite music genres and find new sounds to inspire your musical journey. Start exploring now!';
    let title = 'Indiefy: Explore Genre Diversity: Indie, Electronic, Hip-Hop & More!';

    if (this.$route.params.genre) {
      title = `Indiefy: Explore our ${this.$route.params.genre.replace('-', '/')} Diversity and More Independent Music!`;
      description = `Explore our extensive ${this.$route.params.genre.replace('-', '/')} catalog featuring a wide range of subgenres. Discover your favorite music genres and find new sounds to inspire your musical journey. Start exploring now!`;

      if (this.$route.params.style) {
        title = `Indiefy: Explore our ${this.$route.params.genre.replace('-', '/')} - ${this.$route.params.style} Diversity and More Independent Music!`;
        description = `Explore our extensive ${this.$route.params.genre.replace('-', '/')} - ${this.$route.params.style.replace('-', '/')} catalog featuring a wide range of subgenres. Discover your favorite music genres and find new sounds to inspire your musical journey. Start exploring now!`;
      }
    }

    window.document.title = title;
    document.querySelector('meta[property="og:title"]')['content'] = title;
    document.getElementsByTagName('meta')['description'].content = description;
    document.querySelector('meta[property="og:description"]')['content'] = description;
    document.querySelector('meta[property="og:url"]')['content'] = location.href;

    events.$emit('removePlayer');

    if (!this.$route.params.genre && !this.$route.params.style) {
      setTimeout(() => {
        this.prerenderReady = true;
      }, 1000);
    }

    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 100);
  },
  created: function () {
    this.initParams();
  },
  watch: {
    '$route': function () {
      this.initParams();
    }
  }
};
</script>

<style scoped></style>
