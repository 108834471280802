<template>
  <div>
    <!-- Wrapper Start -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <MainSidebar></MainSidebar>

      <!-- TOP Nav Bar -->
      <NavigationBar></NavigationBar>
      <!-- TOP Nav Bar END -->

      <!-- Page Content  -->
      <!-- Page Content  -->
      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <router-view/>

          <footer class="mb-5 mt-3 pb-5 text-right">
            Created with &hearts; by <a href="https://indiefy.net?utm_campaign=music.indiefy&utm_source=music.indiefy">Indiefy</a>, music distribution for everyone.<br/>
            <a :href="getUrl('en')">English</a> / <a :href="getUrl('es')">Spanish</a>
          </footer>
        </div>
      </div>
    </div>
    <!-- Wrapper END -->
    <!-- Footer -->
    <PlayerContainer></PlayerContainer>
    <!-- <footer class="iq-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="player row">
              <div class="details col-6 col-sm-4 col-md-4 col-lg-4">
                <div class="now-playing"></div>
                <div class="track-art"></div>
                <div>
                  <div class="track-name">Pop Smoke</div>
                  <div class="track-artist">Cascada</div>
                </div>
              </div>
              <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-4">
                <div class="current-time">00:00</div>
                <input type="range" min="1" max="100" value="0" class="seek_slider" onchange="seekTo()" />
                <div class="total-duration">00:00</div>
              </div>
              <div class="buttons col-6 col-sm-3 col-md-2 col-lg-2">
                <div class="prev-track" onclick="prevTrack()">
                  <i class="fa fa-step-backward fa-2x"></i>
                </div>
                <div class="playpause-track" onclick="playpauseTrack()">
                  <i class="fa fa-play-circle fa-3x"></i>
                </div>
                <div class="next-track" onclick="nextTrack()">
                  <i class="fa fa-step-forward fa-2x"></i>
                </div>
              </div>
              <div class="slider_container sound col-sm-6 col-md-2 col-lg-2">
                <i class="fa fa-volume-down"></i>
                <input type="range" min="1" max="100" value="99" class="volume_slider" onchange="setVolume()" />
                <i class="fa fa-volume-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer> -->
    <!-- Footer END -->
  </div>
</template>

<script>
import MainSidebar from "./components/MainSidebar.vue";
import PlayerContainer from "./components/PlayerContainer.vue";
import NavigationBar from './components/NavigationBar.vue';

export default {
  name: "App",
  watch: {
    $route: function () {
    }
  },
  methods: {
    getUrl: function (lang) {
      return `${this.$route.path}?lang=${lang}`;
    }
  },
  components: {
    MainSidebar,
    PlayerContainer,
    NavigationBar,
  },
};
</script>

<style></style>
