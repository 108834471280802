<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-menu-bt d-flex align-items-center">
          <div class="wrapper-menu">
            <div class="main-circle"><i class="las la-bars"></i></div>
          </div>
          <div class="iq-navbar-logo d-flex justify-content-between">
            <router-link to="/" class="header-logo">
              <img src="/assets/img/indiefy-black.png" class="img-fluid rounded-normal" alt="Indiefy" />
            </router-link>
          </div>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
          <i class="ri-menu-3-line"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="list-unstyled iq-menu-top d-flex justify-content-between mb-0 p-0">
            <li :class="{ active: !page }"><router-link to="/">Home</router-link></li>
            <li :class="{ active: page === 'artists' }"><router-link to="/artists">{{ $t('artists') }}</router-link></li>
            <li :class="{ active: page === 'genres' }"><router-link to="/genres">{{ $t('genres') }}</router-link></li>
            <li :class="{ active: page === 'releases' }"><router-link to="/releases">{{ $t('releases') }}</router-link></li>
            <li :class="{ active: page === 'lyrics' }"><router-link to="/lyrics">Lyrics</router-link></li>
          </ul>
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item nav-icon">
              <div class="iq-search-bar mt-2">
                <form @submit.prevent="search()" class="searchbox">
                  <input type="text" v-model="term" class="text search-input" placeholder="Search..." />
                  <a class="search-link" href="javascript:void(0)"><i class="ri-search-line text-black"></i></a>
                </form>
              </div>
            </li>
            <li class="nav-item nav-icon search-content">
              <a href="#" class="search-toggle iq-waves-effect text-gray rounded"><span
                  class="ripple rippleEffect"></span>
                <i class="ri-search-line text-black"></i>
              </a>
              <form @submit.prevent="search()" class="search-box p-0">
                <input type="text" v-model="term" class="text search-input" placeholder="Search...">
                <a class="search-link" href="javascript:void(0)"><i class="ri-search-line text-black"></i></a>
              </form>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  name: 'NavigationBar',
  data: function () {
    return {
      term: '',
      page: ''
    }
  },
  created: function () {
    this.page = this.$route.path.split('/')[1]
  },
  methods: {
    search: function () {
      if (!this.term || this.term.length < 3) {
        return
      }

      this.$router.push(`/artists?search=${this.term}`)
    }
  },
  watch: {
    term: _.debounce(function (value) {
      if (value && value.length >= 3) {
        this.search()
      }
    }, 500),
    $route: function (oldVal, newVal) {
      setTimeout(() => {
        this.page = this.$route.path.split('/')[1]
      }, 10)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>