<template>
  <div class="row profile-content">
    <div class="col-12 col-md-12 col-lg-4">
      <!-- <ArtistCard v-if="artist._id" :artist="artist"></ArtistCard> -->
      <div class="iq-card">
        <div class="iq-card-body profile-page">
          <p class="p-3 text-center" v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </p>
          <div class="profile-header" v-if="release && !loading">
            <div class="cover-container text-center">
              <img width="150" :src="release.cover_image_small || release.cover_image || defaultImage"
                onerror="this.src = '/assets/img/img-placeholder.jpg'" alt="profile-bg" class="rounded-circle img-fluid">
              <div class="profile-detail mt-3">
                <h3>{{ release.title }}</h3>
                <p class="text-primary"><router-link :to="`/artist/${artist.key}`">{{ artist.name }}</router-link></p>
                <p>
                  {{ generateMusicReleaseDescriptions(release.artist.name, release.title, moment(release.release_date).format('LL'), getCountryName(release.user.country), release.main_genre, release.style) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 text-center">
        <ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-8326584585992829"
          data-ad-slot="3714619695"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-8">
      <div class="iq-card" v-if="release">
        <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
          <div class="iq-header-title">
            <h4 class="card-title mb-0">Release Details</h4>
          </div>
        </div>
        <div class="iq-card-body">
          <ul class="list-inline p-0 mb-0">
            <li>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Main Genre</h6>
                <p class="mb-0">{{ release.main_genre }}</p>
              </div>
            </li>
            <li>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Style</h6>
                <p class="mb-0">{{ release.style }}</p>
              </div>
            </li>
            <li>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Release Date</h6>
                <p class="mb-0">{{ moment(release.release_date).format('LL') }}</p>
              </div>
            </li>
            <li v-if="release.copyright_c || release.copyright_holder">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Copyright Holder</h6>
                <p class="mb-0">{{ release.copyright_c || release.copyright_holder }}</p>
              </div>
            </li>
            <li v-if="release.upc">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>UPC Code</h6>
                <p class="mb-0">{{ release.upc }}</p>
              </div>
            </li>
            <li v-if="artist.user && artist.user.country">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Country</h6>
                <p class="mb-0">{{ getCountryName(artist.user.country) }}</p>
              </div>
            </li>
            <li>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Smartlink</h6>
                <p class="mb-0">
                  <a :href="`https://indfy.me/${release.key}`">indfy.me/{{release.key}}</a>
                </p>
              </div>
            </li>
            <li>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h6>Store Links</h6>
                <p class="mb-0 text-right">
                  <a :href="link.url" class="mr-1 d-block" target="_blank" v-for="(link, index) in storeLinks" v-bind:key="`link_${index}`">
                    {{ link.name }}
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12 col-lg-12 text-center">
      <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-8326584585992829"
        data-ad-slot="3714619695"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>

    <div class="col-12 col-md-12 col-lg-12">
      <div class="iq-card" v-if="release && release.songs">
        <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
          <div class="iq-header-title">
            <h4 class="card-title mb-0">Songs / Tracks</h4>
          </div>
        </div>
        <div class="iq-card-body">
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Genre</th>
                  <th scope="col">Style</th>
                  <th scope="col">Featuring</th>
                  <th scope="col" width="50">Lyrics</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(track, index) in release.songs" v-bind:key="`track_${index}`">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <router-link :to="`/releases/${release.key}/${getSongKey(track)}`" v-if="track.lyrics">
                      {{ track.title }}
                    </router-link>
                    <span v-if="!track.lyrics">
                      {{ track.title }}
                    </span>
                  </td>
                  <td>
                    <router-link :to="`/genres/${track.main_genre.replace('/', '-')}`">
                      {{ track.main_genre }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="`/genres/${track.main_genre.replace('/', '-')}/${track.style.replace('/', '-')}`">
                      {{ track.style }}
                    </router-link>
                  </td>
                  <td>
                    <span v-if="track.featuring && !isString(track.featuring)">
                      <span v-for="(feat, index) in track.featuring" v-bind:key="`feat1_${index}`">
                        {{ feat.name }}
                      </span>
                    </span>
                    <span v-if="track.featuring && isString(track.featuring)">
                      {{ track.featuring}}
                    </span>
                  </td>
                  <td>
                    <div v-if="selectedSong && selectedSong.lyrics && selectedSong.title === track.title">
                      {{ track.lyrics }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Music from '../services/music';
import countries from '../data/countries';
import moment from 'moment';
import _ from 'underscore';

export default {
  name: 'ReleaseDetails',
  data: function () {
    return {
      isString: _.isString,
      storeLinks: [],
      selectedSong: null,
      loading: true,
      moment,
      artist: {},
      release: null
    }
  },
  methods: {
    getSongKey: function (track) {
      if (!track) return '';

      let key = track.title.trim().replace(/\s/g, '-').replace(/[^\w\-]/gi, '').replace(/---/, '-').toLowerCase();
      return key;
    },
    generateMusicReleaseDescriptions(artistName, releaseTitle, releaseDate, artistCountry = '', mainGenre = '', subGenre = '') {
      const descriptions = [];

      const excitingPhrases = [
        "Embark on a sonic journey",
        "Experience the mesmerizing sounds",
        "Unleash your musical senses",
        "Immerse yourself in the captivating melodies",
        "Discover a new musical masterpiece",
        "Feel the rhythm of the latest release",
        "Ignite your passion for music",
        "Elevate your musical experience",
        "Dive into a world of sonic bliss",
        "Witness the evolution of sound",
        "Indulge in the sonic masterpiece",
        "Get ready for an auditory adventure",
        "Feel the beat of the latest sensation",
        "Let the music transport you to new horizons",
        "Prepare to be captivated by the latest opus",
        "Experience the fusion of melodies and lyrics",
        "Awaken your senses with the latest release",
        "Discover the harmonious creation",
        "Get lost in the enchanting soundscape",
        "Immerse yourself in the sonic symphony"
      ];

      for (let i = 0; i < 20; i++) {
        let description = '';

        const randomPhrase = excitingPhrases[Math.floor(Math.random() * excitingPhrases.length)];

        description += `${randomPhrase} by ${artistName}`;

        if (releaseTitle) {
          description += `: "${releaseTitle}"`;
        }

        if (releaseDate) {
          const now = new Date();
          const formattedDate = new Date(releaseDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

          if (now > new Date(releaseDate)) {
            description += ` was released on ${formattedDate}`;
          } else {
            description += ` is set to release on ${formattedDate}`;
          }
        }

        if (artistCountry) {
          description += ` from ${artistCountry}`;
        }

        if (mainGenre) {
          description += ` in the ${mainGenre} genre`;
        }

        if (subGenre) {
          description += ` with a touch of ${subGenre}`;
        }

        descriptions.push(description);
      }

      return descriptions[Math.floor(Math.random() * descriptions.length)];
    },
    getCountryName: function (userCountry) {
      let country = _.find(countries, item => item.code === userCountry)

      if (country) {
        return country.name;
      } else {
        return userCountry || 'N/A';
      }
    }
  },
  created: function () {
    Music.getReleases({ key: this.$route.params.key }).then(response => {
      this.release = response.data.items[0];

      if (this.release) {
        this.artist = this.release.artist;
        this.artist.user = this.release.user;
      }

      if (!this.artist.user) {
        this.artist.user = {};
      }

      let docTitle = `${this.release.title} by ${this.artist.name} | Indiefy Music`;
      let bio = this.generateMusicReleaseDescriptions(this.release.artist.name, this.release.title, moment(this.release.release_date).format('LL'), this.getCountryName(this.release.user.country), this.release.main_genre, this.release.style);

      window.document.title = docTitle;
      document.querySelector('meta[property="og:title"]')['content'] = docTitle;
      document.getElementsByTagName('meta')['description'].content = bio;
      document.querySelector('meta[property="og:description"]')['content'] = bio;
      document.getElementById('imagemeta')['content'] = this.artist.cover_image_small || this.artist.cover_image;
      document.querySelector('meta[property="og:image"]')['content'] = this.artist.cover_image_small || this.artist.cover_image;
      document.querySelector('meta[property="og:url"]')['content'] = location.href;

      if (this.$route.params.song && _.find(this.release.songs, item => this.getSongKey(item) === this.$route.params.song)) {
        let selectedSong = _.find(this.release.songs, item => this.getSongKey(item) === this.$route.params.song);
        this.selectedSong = selectedSong;
        window.document.title = `${this.release.title} by ${this.artist.name} (Lyrics) | Indiefy Music`;
        document.querySelector('meta[property="og:title"]')['content'] = `${this.release.title} by ${this.artist.name} (Lyrics) | Indiefy Music`;
      }

      Music.getSmartlink(this.release.key).then(response => {
        this.storeLinks = response.data.stores;

        setTimeout(() => {
          window.prerenderReady = true;
        }, 1000);
      });

      this.loading = false;

      setTimeout(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, 500);
    });
  },
  watch: {
    '$route': function () {
      if (this.$route.params.song && _.find(this.release.songs, item => this.getSongKey(item) === this.$route.params.song)) {
        let selectedSong = _.find(this.release.songs, item => this.getSongKey(item) === this.$route.params.song);
        this.selectedSong = selectedSong;
      }
    }
  },
  components: {
    // ArtistCard
  }
};
</script>

<style scoped></style>
