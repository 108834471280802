<template>
  <div class="row">
    <div class="col-lg-12">
      <LatestMusic></LatestMusic>
    </div>

    <div class="col-lg-12">
      <div class="mb-3 text-center">
        <ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-8326584585992829"
          data-ad-slot="3714619695"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>
    </div>

    <div class="col-lg-12">
      <FeaturedAlbums></FeaturedAlbums>
    </div>

    <div class="col-lg-12">
      <TrendingSongs></TrendingSongs>
    </div>

  </div>
</template>

<script>
import LatestMusic from './LatestMusic.vue'
import FeaturedAlbums from './FeaturedAlbums.vue'
import TrendingSongs from './FeaturedArtists.vue'

export default {
  name: 'HomeComponent',
  mounted: function () {
    if (this.$i18n.locale === 'es') {
      let title = `Indiefy: Explora artistas independientes y emergentes en nuestro catálogo de música`;
      let description = `Descubre un amplio catálogo de música dedicado a artistas independientes, emergentes y una amplia gama de géneros. Descubre joyas ocultas que rompen barreras y redefinen el panorama musical.`;

      window.document.title = title;
      document.querySelector('meta[property="og:title"]')['content'] = title;
      document.getElementsByTagName('meta')['description'].content = description;
      document.querySelector('meta[property="og:description"]')['content'] = description;
    }

    setTimeout(() => {
      window.prerenderReady = true;
    }, 1000 * 3);

    (adsbygoogle = window.adsbygoogle || []).push({});
  },
  components: {
    LatestMusic,
    FeaturedAlbums,
    TrendingSongs
  }
}
</script>

<style scoped>
</style>
