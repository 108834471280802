<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link to="/" class="header-logo">
        <img src="/assets/img/indiefy-black.png" class="img-fluid rounded-normal" alt="Indiefy" />
      </router-link>
      <div class="iq-menu-bt-sidebar">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu">
            <div class="main-circle"><i class="las la-bars"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <ul id="iq-sidebar-toggle" class="iq-menu">
          <li :class="{ active: !page }">
            <router-link to="/" class="iq-waves-effect"><i
                class="las la-home iq-arrow-left"></i><span>Home</span></router-link>
          </li>
          <li :class="{ active: page === 'artists' }">
            <router-link to="/artists" class="iq-waves-effect"><i
                class="las la-users iq-arrow-left"></i><span>{{ $t('artists') }}</span></router-link>
          </li>
          <li :class="{ active: page === 'genres' }">
            <router-link to="/genres" class="iq-waves-effect"><i
                class="las la-headphones iq-arrow-left"></i><span>{{ $t('genres') }}</span></router-link>
          </li>
          <li :class="{ active: page === 'releases' }">
            <router-link to="/releases" class="iq-waves-effect"><i
                class="las la-compact-disc iq-arrow-left"></i><span>{{ $t('releases') }}</span></router-link>
          </li>
          <li :class="{ active: page === 'lyrics' }">
            <router-link to="/lyrics" class="iq-waves-effect"><i
                class="las la-compact-disc iq-arrow-left"></i><span>Lyrics</span></router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSidebar',
  data: function () {
    return {
      page: ''
    }
  },
  created: function () {
    this.page = this.$route.path.split('/')[1]
  },
  watch: {
    $route: function (oldVal, newVal) {
      setTimeout(() => {
        this.page = this.$route.path.split('/')[1]
      }, 10)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>