export default [{
  name: 'Afghanistan',
  code: 'AF'
}, {
  name: 'Åland Islands',
  code: 'AX'
}, {
  name: 'Albania',
  code: 'AL'
}, {
  name: 'Algeria',
  code: 'DZ'
}, {
  name: 'American Samoa',
  code: 'AS'
}, {
  name: 'Andorra',
  code: 'AD'
}, {
  name: 'Angola',
  code: 'AO'
}, {
  name: 'Anguilla',
  code: 'AI'
}, {
  name: 'Antarctica',
  code: 'AQ'
}, {
  name: 'Antigua and Barbuda',
  code: 'AG'
}, {
  name: 'Argentina',
  code: 'AR',
  currency: 'ARS',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Armenia',
  code: 'AM'
}, {
  name: 'Aruba',
  code: 'AW'
}, {
  name: 'Australia',
  code: 'AU',
  currency: 'AUD',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Austria',
  code: 'AT'
}, {
  name: 'Azerbaijan',
  code: 'AZ'
}, {
  name: 'Bahamas',
  code: 'BS'
}, {
  name: 'Bahrain',
  code: 'BH'
}, {
  name: 'Bangladesh',
  code: 'BD',
  currency: 'BDT',
  payout_methods: ['dlocal']
}, {
  name: 'Barbados',
  code: 'BB'
}, {
  name: 'Belarus',
  code: 'BY'
}, {
  name: 'Belgium',
  code: 'BE',
  payout_methods: ['stripe']
}, {
  name: 'Belize',
  code: 'BZ'
}, {
  name: 'Benin',
  code: 'BJ',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Bermuda',
  code: 'BM'
}, {
  name: 'Bhutan',
  code: 'BT'
}, {
  name: 'Bolivia',
  code: 'BO',
  payout_methods: ['stripe', 'dlocal']
}, {
  name: 'Bosnia and Herzegovina',
  code: 'BA'
}, {
  name: 'Botswana',
  code: 'BW'
}, {
  name: 'Bouvet Island',
  code: 'BV'
}, {
  name: 'Brazil',
  code: 'BR',
  currency: 'BRL',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'British Indian Ocean Territory',
  code: 'IO'
}, {
  name: 'Brunei Darussalam',
  code: 'BN'
}, {
  name: 'Bulgaria',
  code: 'BG',
  currency: 'BGN',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Burkina Faso',
  code: 'BF',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Burundi',
  code: 'BI'
}, {
  name: 'Cambodia',
  code: 'KH'
}, {
  name: 'Cameroon',
  code: 'CM',
  payout_methods: ['dlocal']
}, {
  name: 'Canada',
  code: 'CA',
  currency: 'CAD',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Cape Verde',
  code: 'CV'
}, {
  name: 'Cayman Islands',
  code: 'KY'
}, {
  name: 'Central African Republic',
  code: 'CF'
}, {
  name: 'Chad',
  code: 'TD'
}, {
  name: 'Chile',
  code: 'CL',
  currency: 'CLP',
  payout_methods: ['stripe', 'dlocal', 'wise']
}, {
  name: 'China',
  code: 'CN',
  currency: 'CNY',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Christmas Island',
  code: 'CX'
}, {
  name: 'Cocos (Keeling) Islands',
  code: 'CC'
}, {
  name: 'Colombia',
  code: 'CO',
  currency: 'COP',
  payout_methods: ['dlocal', 'sendValu']
}, {
  name: 'Comoros',
  code: 'KM'
}, {
  name: 'Congo',
  code: 'CG'
}, {
  name: 'Congo, The Democratic Republic of the',
  code: 'CD'
}, {
  name: 'Cook Islands',
  code: 'CK'
}, {
  name: 'Costa Rica',
  code: 'CR',
  currency: 'CRC',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Cote D\'Ivoire',
  code: 'CI',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Croatia',
  code: 'HR',
  currency: 'HRK',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Cuba',
  code: 'CU'
}, {
  name: 'Cyprus',
  code: 'CY',
  payout_methods: ['stripe']
}, {
  name: 'Czech Republic',
  code: 'CZ',
  currency: 'CZK',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Denmark',
  code: 'DK',
  currency: 'DKK',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Djibouti',
  code: 'DJ'
}, {
  name: 'Dominica',
  code: 'DM'
}, {
  name: 'Dominican Republic',
  code: 'DO',
  payout_methods: ['stripe', 'dlocal']
}, {
  name: 'Ecuador',
  code: 'EC',
  payout_methods: ['dlocal']
}, {
  name: 'Egypt',
  code: 'EG',
  currency: 'EGP',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'El Salvador',
  code: 'SV'
}, {
  name: 'Equatorial Guinea',
  code: 'GQ'
}, {
  name: 'Eritrea',
  code: 'ER'
}, {
  name: 'Estonia',
  code: 'EE',
  payout_methods: ['stripe']
}, {
  name: 'Ethiopia',
  code: 'ET'
}, {
  name: 'Falkland Islands (Malvinas)',
  code: 'FK'
}, {
  name: 'Faroe Islands',
  code: 'FO'
}, {
  name: 'Fiji',
  code: 'FJ'
}, {
  name: 'Finland',
  code: 'FI',
  payout_methods: ['stripe']
}, {
  name: 'France',
  code: 'FR',
  payout_methods: ['stripe']
}, {
  name: 'French Guiana',
  code: 'GF'
}, {
  name: 'French Polynesia',
  code: 'PF'
}, {
  name: 'French Southern Territories',
  code: 'TF'
}, {
  name: 'Gabon',
  code: 'GA'
}, {
  name: 'Gambia',
  code: 'GM'
}, {
  name: 'Georgia',
  code: 'GE',
  currency: 'GEL',
  payout_methods: ['wise']
}, {
  name: 'Germany',
  code: 'DE',
  payout_methods: ['stripe']
}, {
  name: 'Ghana',
  code: 'GH',
  currency: 'GHS',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Gibraltar',
  code: 'GI'
}, {
  name: 'Greece',
  code: 'GR',
  payout_methods: ['stripe']
}, {
  name: 'Greenland',
  code: 'GL'
}, {
  name: 'Grenada',
  code: 'GD'
}, {
  name: 'Guadeloupe',
  code: 'GP'
}, {
  name: 'Guam',
  code: 'GU'
}, {
  name: 'Guatemala',
  code: 'GT'
}, {
  name: 'Guernsey',
  code: 'GG'
}, {
  name: 'Guinea',
  code: 'GN'
}, {
  name: 'Guinea-Bissau',
  code: 'GW',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Guyana',
  code: 'GY'
}, {
  name: 'Haiti',
  code: 'HT'
}, {
  name: 'Heard Island and Mcdonald Islands',
  code: 'HM'
}, {
  name: 'Holy See (Vatican City State)',
  code: 'VA'
}, {
  name: 'Honduras',
  code: 'HN'
}, {
  name: 'Hong Kong',
  code: 'HK',
  currency: 'HKD',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Hungary',
  code: 'HU',
  currency: 'HUF',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Iceland',
  code: 'IS',
  payout_methods: ['stripe']
}, {
  name: 'India',
  code: 'IN',
  currency: 'INR',
  payout_methods: ['stripe', 'dlocal', 'wise']
}, {
  name: 'Indonesia',
  code: 'ID',
  currency: 'IDR',
  payout_methods: ['stripe', 'dlocal', 'wise']
}, {
  name: 'Iran, Islamic Republic Of',
  code: 'IR'
}, {
  name: 'Iraq',
  code: 'IQ'
}, {
  name: 'Ireland',
  code: 'IE',
  payout_methods: ['stripe']
}, {
  name: 'Isle of Man',
  code: 'IM'
}, {
  name: 'Israel',
  code: 'IL',
  currency: 'ILS',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Italy',
  code: 'IT',
  payout_methods: ['stripe']
}, {
  name: 'Jamaica',
  code: 'JM'
}, {
  name: 'Japan',
  code: 'JP',
  currency: 'JPY',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Jersey',
  code: 'JE'
}, {
  name: 'Jordan',
  code: 'JO'
}, {
  name: 'Kazakhstan',
  code: 'KZ'
}, {
  name: 'Kenya',
  code: 'KE',
  currency: 'KES',
  payout_methods: ['dlocal', 'wise', 'stripe']
}, {
  name: 'Kiribati',
  code: 'KI'
}, {
  name: 'Korea, Democratic People\'S Republic of',
  code: 'KP'
}, {
  name: 'Korea, Republic of',
  code: 'KR',
  currency: 'KRW',
  payout_methods: ['wise']
}, {
  name: 'Kuwait',
  code: 'KW'
}, {
  name: 'Kyrgyzstan',
  code: 'KG'
}, {
  name: 'Lao People\'S Democratic Republic',
  code: 'LA'
}, {
  name: 'Latvia',
  code: 'LV',
  payout_methods: ['stripe']
}, {
  name: 'Lebanon',
  code: 'LB'
}, {
  name: 'Lesotho',
  code: 'LS'
}, {
  name: 'Liberia',
  code: 'LR'
}, {
  name: 'Libyan Arab Jamahiriya',
  code: 'LY'
}, {
  name: 'Liechtenstein',
  code: 'LI',
  payout_methods: ['stripe']
}, {
  name: 'Lithuania',
  code: 'LT',
  payout_methods: ['stripe']
}, {
  name: 'Luxembourg',
  code: 'LU',
  payout_methods: ['stripe']
}, {
  name: 'Macao',
  code: 'MO'
}, {
  name: 'Macedonia, The Former Yugoslav Republic of',
  code: 'MK'
}, {
  name: 'Madagascar',
  code: 'MG'
}, {
  name: 'Malawi',
  code: 'MW'
}, {
  name: 'Malaysia',
  code: 'MY',
  currency: 'MYR',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Maldives',
  code: 'MV'
}, {
  name: 'Mali',
  code: 'ML',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Malta',
  code: 'MT',
  payout_methods: ['stripe']
}, {
  name: 'Marshall Islands',
  code: 'MH'
}, {
  name: 'Martinique',
  code: 'MQ'
}, {
  name: 'Mauritania',
  code: 'MR'
}, {
  name: 'Mauritius',
  code: 'MU'
}, {
  name: 'Mayotte',
  code: 'YT'
}, {
  name: 'Mexico',
  code: 'MX',
  currency: 'MXN',
  payout_methods: ['stripe', 'dlocal', 'wise']
}, {
  name: 'Micronesia, Federated States of',
  code: 'FM'
}, {
  name: 'Moldova, Republic of',
  code: 'MD'
}, {
  name: 'Monaco',
  code: 'MC'
}, {
  name: 'Mongolia',
  code: 'MN'
}, {
  name: 'Montserrat',
  code: 'MS'
}, {
  name: 'Morocco',
  code: 'MA',
  currency: 'MAD',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Mozambique',
  code: 'MZ'
}, {
  name: 'Myanmar',
  code: 'MM'
}, {
  name: 'Namibia',
  code: 'NA'
}, {
  name: 'Nauru',
  code: 'NR'
}, {
  name: 'Nepal',
  code: 'NP',
  currency: 'NPR',
  payout_methods: ['wise']
}, {
  name: 'Netherlands',
  code: 'NL',
  payout_methods: ['stripe']
}, {
  name: 'Netherlands Antilles',
  code: 'AN'
}, {
  name: 'New Caledonia',
  code: 'NC'
}, {
  name: 'New Zealand',
  code: 'NZ',
  currency: 'NZD',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Nicaragua',
  code: 'NI'
}, {
  name: 'Niger',
  code: 'NE',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Nigeria',
  code: 'NG',
  currency: 'NGN',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Niue',
  code: 'NU'
}, {
  name: 'Norfolk Island',
  code: 'NF'
}, {
  name: 'Northern Mariana Islands',
  code: 'MP'
}, {
  name: 'Norway',
  code: 'NO',
  currency: 'NOK',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Oman',
  code: 'OM'
}, {
  name: 'Pakistan',
  code: 'PK',
  payout_methods: []
}, {
  name: 'Palau',
  code: 'PW'
}, {
  name: 'Palestinian Territory, Occupied',
  code: 'PS'
}, {
  name: 'Panama',
  code: 'PA',
  payout_methods: ['dlocal']
}, {
  name: 'Papua New Guinea',
  code: 'PG'
}, {
  name: 'Paraguay',
  code: 'PY',
  payout_methods: ['dlocal']
}, {
  name: 'Peru',
  code: 'PE',
  currency: 'PEN',
  payout_methods: ['stripe', 'dlocal', 'wise']
}, {
  name: 'Philippines',
  code: 'PH',
  currency: 'PHP',
  payout_methods: ['dlocal', 'wise', 'stripe']
}, {
  name: 'Pitcairn',
  code: 'PN'
}, {
  name: 'Poland',
  code: 'PL',
  currency: 'PLN',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Portugal',
  code: 'PT',
  payout_methods: ['stripe']
}, {
  name: 'Puerto Rico',
  code: 'PR'
}, {
  name: 'Qatar',
  code: 'QA'
}, {
  name: 'Reunion',
  code: 'RE'
}, {
  name: 'Romania',
  code: 'RO',
  currency: 'RON',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Russian Federation',
  code: 'RU',
  currency: 'RUB',
  payout_methods: ['wise']
}, {
  name: 'RWANDA',
  code: 'RW'
}, {
  name: 'Saint Helena',
  code: 'SH'
}, {
  name: 'Saint Kitts and Nevis',
  code: 'KN'
}, {
  name: 'Saint Lucia',
  code: 'LC'
}, {
  name: 'Saint Pierre and Miquelon',
  code: 'PM'
}, {
  name: 'Saint Vincent and the Grenadines',
  code: 'VC'
}, {
  name: 'Samoa',
  code: 'WS'
}, {
  name: 'San Marino',
  code: 'SM'
}, {
  name: 'Sao Tome and Principe',
  code: 'ST'
}, {
  name: 'Saudi Arabia',
  code: 'SA',
  payout_methods: ['stripe']
}, {
  name: 'Senegal',
  code: 'SN',
  currency: 'XOF',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Serbia',
  code: 'RS'
}, {
  name: 'Serbia and Montenegro',
  code: 'CS'
}, {
  name: 'Montenegro',
  code: 'ME'
}, {
  name: 'Seychelles',
  code: 'SC'
}, {
  name: 'Sierra Leone',
  code: 'SL'
}, {
  name: 'Singapore',
  code: 'SG',
  currency: 'SGD',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Slovakia',
  code: 'SK',
  payout_methods: ['stripe']
}, {
  name: 'Slovenia',
  code: 'SI',
  payout_methods: ['stripe']
}, {
  name: 'Solomon Islands',
  code: 'SB'
}, {
  name: 'Somalia',
  code: 'SO'
}, {
  name: 'South Africa',
  code: 'ZA',
  currency: 'ZAR',
  payout_methods: ['dlocal', 'wise', 'stripe']
}, {
  name: 'South Georgia and the South Sandwich Islands',
  code: 'GS'
}, {
  name: 'Spain',
  code: 'ES',
  payout_methods: ['stripe']
}, {
  name: 'Sri Lanka',
  code: 'LK',
  currency: 'LKR',
  payout_methods: ['wise']
}, {
  name: 'Sudan',
  code: 'SD'
}, {
  name: 'Suriname',
  code: 'SR'
}, {
  name: 'Svalbard and Jan Mayen',
  code: 'SJ'
}, {
  name: 'Swaziland',
  code: 'SZ'
}, {
  name: 'Sweden',
  code: 'SE',
  currency: 'SEK',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Switzerland',
  code: 'CH',
  currency: 'CHF',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Syrian Arab Republic',
  code: 'SY'
}, {
  name: 'Taiwan, Province of China',
  code: 'TW'
}, {
  name: 'Tajikistan',
  code: 'TJ'
}, {
  name: 'Tanzania, United Republic of',
  code: 'TZ'
}, {
  name: 'Thailand',
  code: 'TH',
  currency: 'THB',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'Timor-Leste',
  code: 'TL'
}, {
  name: 'Togo',
  code: 'TG',
  currency: 'XOF',
  payout_methods: ['wise']
}, {
  name: 'Tokelau',
  code: 'TK'
}, {
  name: 'Tonga',
  code: 'TO'
}, {
  name: 'Trinidad and Tobago',
  code: 'TT',
  payout_methods: ['stripe']
}, {
  name: 'Tunisia',
  code: 'TN'
}, {
  name: 'Turkey',
  code: 'TR',
  currency: 'TRY',
  payout_methods: ['dlocal', 'wise', 'stripe']
}, {
  name: 'Turkmenistan',
  code: 'TM'
}, {
  name: 'Turks and Caicos Islands',
  code: 'TC'
}, {
  name: 'Tuvalu',
  code: 'TV'
}, {
  name: 'Uganda',
  code: 'UG',
  payout_methods: []
}, {
  name: 'Ukraine',
  code: 'UA',
  currency: 'UAH',
  payout_methods: ['wise']
}, {
  name: 'United Arab Emirates',
  code: 'AE',
  currency: 'AED',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'United Kingdom',
  code: 'GB',
  currency: 'GBP',
  payout_methods: ['stripe', 'wise']
}, {
  name: 'United States',
  code: 'US',
  currency: 'USD',
  payout_methods: ['stripe']
}, {
  name: 'United States Minor Outlying Islands',
  code: 'UM'
}, {
  name: 'Uruguay',
  code: 'UY',
  currency: 'UYU',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Uzbekistan',
  code: 'UZ'
}, {
  name: 'Vanuatu',
  code: 'VU'
}, {
  name: 'Venezuela',
  code: 'VE'
}, {
  name: 'Viet Nam',
  code: 'VN',
  currency: 'VND',
  payout_methods: ['dlocal', 'wise']
}, {
  name: 'Virgin Islands, British',
  code: 'VG'
}, {
  name: 'Virgin Islands, U.S.',
  code: 'VI'
}, {
  name: 'Wallis and Futuna',
  code: 'WF'
}, {
  name: 'Western Sahara',
  code: 'EH'
}, {
  name: 'Yemen',
  code: 'YE'
}, {
  name: 'Zambia',
  code: 'ZM',
  currency: 'ZMW',
  payout_methods: ['wise']
}, {
  name: 'Zimbabwe',
  code: 'ZW'
}]
