export default {
  Alternative: [
    'All Styles',
    'Alternative',
    'Chinese Alt',
    'College Rock',
    'EMO',
    'Goth Rock',
    'Grunge',
    'Indie Pop',
    'Indie Rock',
    'Korean Indie',
    'New Wave',
    'Pop Punk',
    'Punk',
    'Instrumental'
  ],
  Blues: [
    'All Styles',
    'Acoustic Blues',
    'Blues',
    'Chicago Blues',
    'Classic Blues',
    'Contemporary Blues',
    'Country Blues',
    'Delta Blues',
    'Electric Blues'
  ],
  'Children\'s Music': [
    'All Styles',
    'Children\'s Music',
    'Lullabies',
    'Sing-Along',
    'Stories'
  ],
  Classical: [
    'All Styles',
    'Art Song',
    'Avant-Garde',
    'Baroque Era',
    'Brass & Woodwinds',
    'Cantata',
    'Cello',
    'Chamber Music',
    'Chant',
    'Choral',
    'Classical',
    'Classical Crossover',
    'Classical Era',
    'Contemporary Era',
    'Electronic',
    'Guitar',
    'Impressionist',
    'Medieval Era',
    'Minimalism',
    'Modern Era',
    'Opera',
    'Oratorio',
    'Orchestral',
    'Percussion',
    'Piano',
    'Romantic Era',
    'Sacred',
    'Solo Instrumental',
    'Violin'
  ],
  Comedy: [
    'All Styles',
    'Comedy',
    'Novelty',
    'Standup Comedy'
  ],
  Country: [
    'All Styles',
    'Alternative Country',
    'Americana',
    'Bluegrass',
    'Contemporary Bluegrass',
    'Contemporary Country',
    'Contemporaty Country',
    'Country',
    'Country Gospel',
    'Honky Tonk',
    'Outlaw Country',
    'Thai Country',
    'Traditional Bluegrass',
    'Traditional Country',
    'Urban Cowboy'
  ],
  Dance: [
    'All Styles',
    'Big Room',
    'Breakbeat',
    'Breaks',
    'Chill Out',
    'Dance',
    'Deep House',
    'DJ Tools',
    'Drum & Bass',
    'Dubstep',
    'Electro House',
    'Electronica',
    'Future House',
    'Garage',
    'Glitch Hop',
    'Hard Dance',
    'Hardcore',
    'Hardcore / Hard Techno',
    'House',
    'Indie Dance / Nu Disco',
    'Jungle/Drum\'n\'bass',
    'Minimal',
    'Progressive House',
    'Psy-Trance',
    'Tech House',
    'Techno',
    'Trance'
  ],
  Electronic: [
    'All Styles',
    'Ambient',
    'Bass',
    'Breaks',
    'Chill Out',
    'Deep House',
    'DJ Tools',
    'Downtempo',
    'Drum & Bass',
    'Dubstep',
    'Electro House',
    'Electronic',
    'Electronica',
    'Glitch Hop',
    'Hard Dance',
    'Hardcore / Hard Techno',
    'House',
    'IDM/Experimental',
    'Indie Dance / Nu Disco',
    'Industrial',
    'Minimal',
    'Progressive House',
    'Psy-Trance',
    'Tech House',
    'Techno',
    'Trance'
  ],
  Folk: [
    'All Styles',
    'Folk'
  ],
  'Hip Hop/Rap': [
    'All Styles',
    'Alternative Rap',
    'Chinese Hip-Hop',
    'Dirty South',
    'East Coast Rap',
    'Gangsta Rap',
    'Hardcore Rap',
    'Hip Hop/Rap',
    'Hip-Hop',
    'Korean Hip-Hop',
    'Latin Rap',
    'Old School Rap',
    'Rap',
    'Trap',
    'UK Hip Hop',
    'Underground Rap',
    'West Coast Rap'
  ],
  Holiday: [
    'All Styles',
    'Christmas',
    'Christmas: Classical',
    'Christmas: Jazz',
    'Christmas: Modern',
    'Christmas: Pop',
    'Christmas: R&B',
    'Christmas: Religious',
    'Christmas: Rock',
    'Easter',
    'Halloween',
    'Holiday'
  ],
  Inspirational: [
    'All Styles',
    'Inspirational'
  ],
  Jazz: [
    'All Styles',
    'Acid Jazz',
    'Avant-Garde Jazz',
    'Bebop',
    'Big Band',
    'Contemporary Jazz',
    'Cool',
    'Crossover Jazz',
    'Dixieland',
    'Fusion',
    'Hard Bop',
    'Jazz',
    'Latin Jazz',
    'Mainstream Jazz',
    'Ragtime',
    'Smooth Jazz',
    'Traditional Jazz',
    'Vocal Jazz'
  ],
  Latin: [
    'All Styles',
    'Alternativo & Rock Latino',
    'Baladas y Boleros',
    'Contemporary Latin',
    'Latin',
    'Latin Jazz',
    'Latin Urban',
    'Pop Latino',
    'Raices',
    'Regional Mexicano',
    'Salsa y Tropical',
    'Reggaeton',
    'Trap'
  ],
  'New Age': [
    'All Styles',
    'Healing',
    'Meditation',
    'Nature',
    'Relaxation',
    'Travel',
    'Yoga'
  ],
  Pop: [
    'All Styles',
    'Adult Contemporary',
    'Alternative Pop',
    'Britpop',
    'Cantopop',
    'Dance Pop',
    'Disco',
    'French Pop',
    'German Pop',
    'Indo Pop',
    'K-Pop',
    'Korean Folk-Pop',
    'Malaysian Pop',
    'Manilla Sound',
    'New Wave',
    'Oldies',
    'Original Pilipino Music',
    'Pinoy Pop',
    'Pop',
    'Pop/Rock',
    'Soft Rock',
    'Tai-Pop',
    'Teen Pop',
    'Thai Pop'
  ],
  'R&B/Soul': [
    'All Styles',
    'Contemporary R&B',
    'Disco',
    'Doo Wop',
    'Funk',
    'Motown',
    'Neo-Soul',
    'R&B/Soul',
    'Soul',
    'Soul/R&B',
    'Christian & Gospel',
    'Christian Metal',
    'Christian Pop',
    'Christian Rap',
    'Christian Rock',
    'Gospel',
    'Praise & Worship',
    'R&B'
  ],
  Reggae: [
    'All Styles',
    'Dancehall/Reggae',
    'Dub',
    'Lovers Rock',
    'Modern Dancehall',
    'Pop-Reggae',
    'Ragga',
    'Reggae',
    'Roots Reggae',
    'Ska'
  ],
  Rock: [
    'All Styles',
    'Adult Alternative',
    'American Trad Rock',
    'Arena Rock',
    'Blues-Rock',
    'British Invasion',
    'Chinese Rock',
    'Classic Rock',
    'Death Metal/Black Metal',
    'Glam Rock',
    'Hair Metal',
    'Hard Rock',
    'Heavy Metal',
    'Jam Bands',
    'Korean Rock',
    'Metal',
    'Prog-Rock/Art Rock',
    'Psychedelic',
    'Psychedelic Rock',
    'Rock',
    'Rock & Roll',
    'Rock\'n\'roll',
    'Rockabilly',
    'Roots Rock',
    'Singer/Songwriter',
    'Southern Rock',
    'Surf',
    'Tex-Mex'
  ],
  'Spoken Word': [
    'All Styles',
    'Spoken Word'
  ],
  Vocal: [
    'All Styles',
    'Standards',
    'Traditional Pop',
    'Vocal',
    'Vocal Pop'
  ],
  World: [
    'All Styles',
    'Africa',
    'Afrikaans',
    'Afro-Beat',
    'Afro-Pop',
    'Arabesque',
    'Asia',
    'Calypso',
    'Caribbean',
    'Celtic Folk',
    'Dangdut',
    'Dini',
    'Europe',
    'Farsi',
    'Flamenco',
    'France',
    'Iberia',
    'Indonesian Religious',
    'Inspirational',
    'Israeli',
    'Japan',
    'Klezmer',
    'North America',
    'Polka',
    'Russian',
    'Sanat',
    'Soca',
    'South Africa',
    'South America',
    'Tango',
    'Traditional Celtic',
    'Turkish',
    'World',
    'Worldbeat',
    'Zydeco'
  ]
}
