<template>
  <div class="mb-3 text-center">
    <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-8326584585992829"
      data-ad-slot="3714619695"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  </div>
  <div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between align-items-center">
      <div class="iq-header-title">
        <h1 class="card-title h4">{{ $t('showing') }} {{ totalArtists }} {{ $t('artists') }}</h1>
      </div>
      <div id="feature-album-slick-arrow" class="slick-aerrow-block">
        <router-link :to="`/artists?page=${getPreviousPage() + 1}&genre=${currentGenre || ''}`" class="btn btn-primary" :disabled="currentPage === 0">
          <i class="fa fa-chevron-left"></i>
        </router-link>
        <a href="javascript:void(0)" class="btn btn-default">Page {{ currentPage + 1 }} of {{ getTotalPages() }}</a>
        <router-link :to="`/artists?page=${getNextPage()}&genre=${currentGenre || ''}`"  class="btn btn-primary" :disabled="currentPage >= getTotalPages()">
          <i class="fa fa-chevron-right"></i>
        </router-link>
      </div>
    </div>
    <div class="iq-card-body">
      <p class="text-center p-3" v-if="loading">
        <i class="fa fa-spinner fa-spin"></i>
      </p>
      <div class="mb-3" v-if="!loading">
        <router-link :to="`/artists?genre=&page=1`" class="btn btn-warning" v-if="currentGenre">
          {{ currentGenre }} <span class="fa fa-times ml-1"></span>
        </router-link>
      </div>
      <ul class="list-unstyled row iq-box-hover mb-0" v-if="!loading">
        <li class="col-xl-3 col-lg-3 col-md-4 iq-music-box" v-for="(artist, index) in artists" v-bind:key="`artists_${index}`">
          <div class="iq-card">
            <div class="iq-card-body p-0">
              <div class="iq-thumb">
                <div class="iq-music-overlay"></div>
                <router-link :to="`/artist/${artist.key}`">
                  <img onerror="this.src = '/assets/img/img-placeholder.jpg'" :src="artist.cover_image_small || artist.cover_image || defaultImage" class="img-border-radius img-fluid w-100" alt="">
                </router-link>
                <div class="overlay-music-icon">
                  <router-link :to="`/artist/${artist.key}`">
                    <i class="las la-eye"></i>
                  </router-link>
                </div>
              </div>
              <div class="feature-list text-center">
                <h6 class="font-weight-600 mb-0">
                  <router-link :to="`/artist/${artist.key}`">
                    {{ artist.name }}
                  </router-link>
                </h6>
                <p class="mb-0">
                  <router-link :to="`/artists?genre=${artist.main_genre}&page=1`">{{ artist.main_genre }}</router-link> / {{ getCountryName(artist.user.country) }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Music from '../services/music';
import countries from '../data/countries';
import _ from 'underscore';
import events from '../utils/events';

export default {
  name: 'ArtistsPage',
  data: function () {
    return {
      currentGenre: '',
      defaultImage: '/assets/img/img-placeholder.jpg',
      artists: [],
      loading: true,
      totalArtists: 0,
      searchLimit: 25,
      currentPage: 0,
      results: 0,
      search: '',
    }
  },
  methods: {
    getCountryName: function (userCountry) {
      let country = _.find(countries, item => item.code === userCountry)

      if (country) {
        return country.name;
      } else {
        return userCountry || 'N/A';
      }
    },
    getPreviousPage: function () {
      if (this.currentPage > 0) {
        return this.currentPage - 1
      } else {
        return 0
      }
    },
    getNextPage: function () {
      if ((this.currentPage + 1) >= this.getTotalPages()) {
        return this.getTotalPages()
      } else {
        return this.currentPage + 2
      }
    },
    getTotalPages: function () {
      return Math.ceil(this.totalArtists / this.searchLimit) || '1';
    },
    initParams: function () {
      this.currentGenre = this.$route.query.genre;

      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page) - 1;
      } else {
        this.currentPage = 0;
      }

      if (this.$route.query.search) {
        this.search = this.$route.query.search;
      }

      if (this.currentGenre) {
        window.document.title = `Listen to Independent ${this.currentGenre} Artists | Indiefy`;
      } else {
        window.document.title = `Listen to Independent Artists From All Over The World | Indiefy`;
      }
    },
    getArtists: function () {
      let filter = {
        published: 1,
        limit: this.searchLimit,
        page: this.currentPage,
        search: this.search,
        main_genre: this.currentGenre,
        orderBy: ['spotify_followers', 'popularity']
      }

      this.loading = true
      this.results = 0
      this.totalArtists = 0

      Music.getArtists(filter).then(response => {
        this.artists = response.data.items
        this.results = response.data.results
        this.totalArtists = response.data.total
        this.loading = false

        setTimeout(() => {
          window.prerenderReady = true;
        }, 1000);
      })
    }
  },
  watch: {
    '$route': function () {
      this.initParams()
      this.getArtists()
    },
  },
  mounted: function () {
    events.$emit('removePlayer');

    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 100);
  },
  created: function () {
    this.initParams()
    this.getArtists()
  }
};
</script>

<style scoped></style>
