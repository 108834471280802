import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import HomeComponent from './components/HomeComponent.vue';
import ArtistPage from './components/ArtistPage.vue';
import ArtistsPage from './components/ArtistsPage.vue';
import GenresPage from './components/GenresPage.vue';
import ReleasesPage from './components/ReleasesPage.vue';
import ReleaseDetails from './components/ReleaseDetails.vue';
import LyricsPage from './components/LyricsPage.vue';
import LyricPage from './components/LyricPage.vue';
import { createI18n } from 'vue-i18n';

const routes = [
  { path: '/', component: HomeComponent },
  { path: '/artist/:key', component: ArtistPage },
  { path: '/artists', component: ArtistsPage },
  { path: '/genres', component: GenresPage },
  { path: '/genres/:genre', component: GenresPage },
  { path: '/genres/:genre/:style', component: GenresPage },
  { path: '/releases', component: ReleasesPage },
  { path: '/lyrics', component: LyricsPage },
  { path: '/lyrics/:genre', component: LyricsPage },
  { path: '/lyrics/:genre/:style', component: LyricsPage },
  { path: '/releases/:key', component: ReleaseDetails },
  { path: '/releases/:key/:song', component: ReleaseDetails },
  { path: '/lyric/:id', component: LyricPage },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

if (location.host.match(/localhost/)) {
  window.apiURL = 'http://localhost:3000/v1/';
} else {
  window.apiURL = 'https://indiefy.net/v1/';
}

let lang = window.navigator.userLanguage || window.navigator.language.split('_')[0]
lang = lang.split('-')[0]
console.log('Browser language:', lang)

function getSearchParam (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[]]/g, '\\$&')
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  let results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

if (window.location.search.includes('lang=')) {
  lang = getSearchParam('lang')
  window.appLang = lang
}

if (!['en', 'es'].includes(lang)) {
  lang = 'en'
  window.appLang = 'en'
}

import(`../public/langs/${lang}.json`).then(messages => {
  let data = {}
  data[lang] = messages

  const i18n = createI18n({
    locale: lang,
    allowComposition: true,
    messages: data
  })

  i18n.locale = lang

  const app = createApp(App)
  app.use(i18n)
  app.use(router)
  app.mount('#app')
})
