<template>
  <div class="player-container" ref="playerContainer">
  </div>
</template>

<script>
import events from '../utils/events'

export default {
  name: 'PlayerContainer',
  created: function () {
    events.$on('setPlayer', item => {
      if (item.spotify_url && item.spotify_url.match(/\/album/gi)) {
        window.$(this.$refs.playerContainer).html(`<iframe src="https://embed.spotify.com/?uri=spotify:album:${item.spotify_url.split('album/')[1]}" width="100%" height="80" frameborder="0" allowtransparency="true"></iframe>`);
      } else {
        this.$router.push(`/releases/${item.key}`)
      }
    });

    events.$on('removePlayer', () => {
      window.$(this.$refs.playerContainer).html('');
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  z-index: 99;
}
</style>
