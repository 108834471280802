<template>
  <div class="iq-card iq-realease">
    <div class="iq-card-header d-flex justify-content-between border-0">
      <div class="iq-header-title">
        <h1 class="card-title h4">{{ $t('new_releases') }}</h1>
      </div>
      <div class="d-flex align-items-center iq-view">
        <b class="mb-0 text-primary"><router-link to="/releases">View All <i class="las la-angle-right"></i></router-link></b>
      </div>
    </div>
    <div class="iq-card-body iq-realeses-back" v-bind:style="`background: url('${selectedItem.cover_image_small || selectedItem.cover_image || defaultImage}') center center no-repeat;`">
      <div class="releases-overlay"></div>
      <div class="row">
        <div class="col-lg-5 iq-realese-box">
          <div v-if="selectedItem._id">
            <div class="release-description" v-html="generateSongDescription(selectedItem.artist.name, selectedItem.title, selectedItem.user.country, selectedItem.main_genre, selectedItem.style)">
            </div>
            <!-- <div class="track-name1">Listen to "{{ selectedItem.title }}" by</div>
            <div class="track-artist1">
              <span v-if="selectedItem.artist">
                <img v-if="selectedItem.user" :src="`/assets/img/countries/${selectedItem.user.country || 'default'}.png`" width="18" class="mr-1"> {{ selectedItem.artist.name }}<span v-if="selectedItem.user.country"> from {{ getCountryName(selectedItem.user.country) }}</span>
              </span>
            </div> -->
          </div>

          <div class="iq-music-img">
            <div class="equalizer">
              <span class="bar bar-1"></span>
              <span class="bar bar-2"></span>
              <span class="bar bar-3"></span>
              <span class="bar bar-4"></span>
              <span class="bar bar-5"></span>
            </div>
          </div>
          <!-- <div class="player1 row">
            <div class="details1 music-list col-6 col-sm-6 col-lg-6" v-if="selectedItem._id">
              <div class="now-playing1"></div>
              <div class="track-art1"></div>
              <div>
                <div class="track-name1">{{ selectedItem.title }}</div>
                <div class="track-artist1">
                  <span v-if="selectedItem.artist">
                    {{ selectedItem.artist.name }}
                  </span>
                </div>
              </div>
            </div>
            <div class="buttons1 col-6 col-sm-2 col-lg-3">
              <div class="prev-track1" onclick="prevTrack1()">
                <i class="fa fa-step-backward fa-2x"></i>
              </div>
              <div class="playpause-track1" onclick="playpauseTrack1()">
                <i class="fa fa-play-circle fa-3x"></i>
              </div>
              <div class="next-track1" onclick="nextTrack1()">
                <i class="fa fa-step-forward fa-2x"></i>
              </div>
            </div>
          </div> -->
        </div>
        <div class="col-lg-7">
          <ul class="list-unstyled iq-song-slide mb-0 realeases-banner" ref="releasesBanner">
            <li v-for="(item, index) in music" v-bind:key="`latest_${index}`" class="row" @click="selectTrack(item)">
              <div class="d-flex justify-content-between align-items-center">
                <div class="media align-items-center col-10 col-md-5">
                  <div class="iq-realese-song">
                    <router-link :to="`/artist/${item.artist.key}`">
                      <img :src="item.cover_image_small || item.cover_image || item.artist.cover_image" onerror="this.src = '/assets/img/img-placeholder.jpg'"
                        class="img-border-radius avatar-60 img-fluid" alt="" />
                    </router-link>
                  </div>
                  <div class="media-body text-white ml-3">
                    <p class="mb-0 iq-music-title">
                      <router-link :to="`/releases/${item.key}`" class="text-white">
                        {{item.title }}
                      </router-link>
                    </p>
                    <small>
                      <router-link :to="`/artist/${item.artist.key}`">
                        {{ item.artist.name }}
                      </router-link>
                    </small>
                  </div>
                </div>
                <p class="mb-0 col-md-2 iq-m-time"></p>
                <p class="mb-0 col-md-2 iq-m-icon">
                  <!-- <i class="lar la-star font-size-20"></i> -->
                </p>
                <p class="mb-0 col-2 col-md-2">
                  <!-- <i class="las la-play-circle font-size-32"></i> -->
                </p>
                <p class="iq-music-drop d-flex align-items-center col-md-1">
                  <i class="las la-play-circle font-size-32"></i>
                </p>
                <!-- <div class="iq-card-header-toolbar iq-music-drop d-flex align-items-center col-md-1">
                  <div class="dropdown">
                    <span class="dropdown-toggle text-primary" id="dropdownMenuButton1" data-toggle="dropdown"
                      aria-expanded="false" role="button">
                      <i class="ri-more-2-fill text-primary"></i>
                    </span>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1"
                      style="">
                      <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>
                      <a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                      <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>
                    </div>
                  </div>
                </div> -->
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Music from '../services/music'
import _ from 'underscore'
import events from '../utils/events'
import countries from '../data/countries'

export default {
  name: 'LatestMusic',
  data: function () {
    return {
      defaultImage: '/assets/img/img-placeholder.jpg',
      music: [],
      selectedItem: {},
    }
  },
  methods: {
    generateSongDescription(artistName, songName, artistCountry, songGenre, songSubgenre) {
      let randomDescriptions = [
        `Get ready to groove with <b>${artistName}'s</b> latest release <b>"${songName}"</b>! This talented artist from <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b> brings you an irresistible blend of <b>${songGenre}</b> with a touch of <b>${songSubgenre}</b>. Let the captivating melodies and infectious beats take you on a musical journey like never before.`,
        `Dive into the enchanting world of <b>${artistName}</b> as they unleash <b>"${songName}"</b> upon the music scene! Hailing from <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b>, this gifted artist presents an extraordinary fusion of <b>${songGenre}</b> and <b>${songSubgenre}</b>. Let the mesmerizing rhythm and soulful lyrics transport you to new musical horizons.`,
        `Experience the magic of <b>${artistName}'s</b> chart-topping release <b>"${songName}"</b>! Originating from <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b>, this talented artist delivers an electrifying blend of <b>${songGenre}</b> and <b>${songSubgenre}</b>. Get ready to lose yourself in the captivating soundscape and let the music ignite your senses.`,
        `Embrace the captivating sounds of <b>${artistName}'s</b> sensational release <b>"${songName}"</b>! Straight from <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b>, this visionary artist blends elements of <b>${songGenre}</b> and <b>${songSubgenre}</b> into an unforgettable sonic experience. Let the music resonate with your soul and leave an everlasting impact.`,
        `<b>${artistName}</b> strikes again with <b>"${songName}"</b> - a true masterpiece! This sensational artist from <b>${this.getCountryName(artistCountry)}</b> unveils a genre-defying gem that seamlessly combines <b>${songGenre}</b> and <b>${songSubgenre}</b>. Allow the evocative melodies and poignant lyrics to transport you to a world of musical bliss.`,
        `Prepare to be spellbound by <b>${artistName}'s</b> captivating creation <b>"${songName}"</b>! With roots in <b>${this.getCountryName(artistCountry)}</b>, this extraordinary artist pushes boundaries by merging <b>${songGenre}</b> with elements of <b>${songSubgenre}</b>. Immerse yourself in the harmonious journey and let the music awaken your senses.`,
        `Unlock the hidden depths of your emotions with <b>"${songName}"</b> from <b>${artistName}</b> This talented artist hailing from <b>${this.getCountryName(artistCountry)}</b> unveils an introspective blend of <b>${songGenre}</b> and <b>${songSubgenre}</b>. Let the evocative soundscape resonate within you and unveil new layers of musical expression.`,
        `Embark on a sonic adventure with <b>${artistName}'s</b> latest offering <b>"${songName}"</b>! From <b>${this.getCountryName(artistCountry)}</b>, this visionary artist seamlessly weaves together the elements of <b>${songGenre}</b> and <b>${songSubgenre}</b>. Immerse yourself in the hypnotic melodies and let the music transport you to a world of pure imagination.`,
      ];

      if (this.$i18n.locale === 'es') {
        randomDescriptions = [
          `¡Prepárate para disfrutar con el último lanzamiento de <b>${artistName}</b>, <b>"${songName}"</b>! Este talentoso artista proveniente de <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b> te trae una irresistible combinación de <b>${songGenre}</b> con un toque de <b>${songSubgenre}</b>. Deja que las cautivadoras melodías y los ritmos contagiosos te lleven en un viaje musical como nunca antes.`,
          `Sumérgete en el mundo encantador de <b>${artistName}</b> mientras desatan <b>"${songName}"</b> en la escena musical. Provenientes de <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b>, este talentoso artista presenta una extraordinaria fusión de <b>${songGenre}</b> y <b>${songSubgenre}</b>. Deja que el ritmo hipnótico y las letras emotivas te transporten a nuevos horizontes musicales.`,
          `¡Experimenta la magia del exitoso lanzamiento de <b>${artistName}</b>, <b>"${songName}"</b>! Originario de <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b>, este talentoso artista ofrece una mezcla electrizante de <b>${songGenre}</b> y <b>${songSubgenre}</b>. Prepárate para perderte en el cautivador paisaje sonoro y deja que la música encienda tus sentidos.`,
          `Abraza los cautivadores sonidos del sensacional lanzamiento de <b>${artistName}</b>, <b>"${songName}"</b> ¡Directamente desde <img src="/assets/img/countries/${artistCountry || 'default'}.png" onerror="this.src = '/assets/img/countries/default.png'" width="18" class="mr-1"> <b>${this.getCountryName(artistCountry)}</b>, este talentoso artista fusiona elementos de <b>${songGenre}</b> y <b>${songSubgenre}</b> en una experiencia sonora inolvidable. Deja que la música resuene en tu alma y deje una huella duradera.`,
          `<b>${artistName}</b> vuelve a sorprender con <b>"${songName}"</b> - ¡una verdadera obra maestra! Este sensacional artista proveniente de <b>${this.getCountryName(artistCountry)}</b> presenta una joya que desafía los géneros al combinar de manera impecable <b>${songGenre}</b> y <b>${songSubgenre}</b>. Permite que las melodías evocadoras y las letras conmovedoras te transporten a un mundo de deleite musical.`,
          `¡Prepárate para quedar cautivado por la creación fascinante de <b>${artistName}</b>, <b>"${songName}"</b>! Con raíces en <b>${this.getCountryName(artistCountry)}</b>, este extraordinario artista rompe barreras al fusionar <b>${songGenre}</b> con elementos de <b>${songSubgenre}</b>. Sumérgete en este viaje armónico y deja que la música despierte tus sentidos.`,
          `Descubre las profundidades ocultas de tus emociones con <b>"${songName}"</b> de <b>${artistName}</b>. Este talentoso artista proveniente de <b>${this.getCountryName(artistCountry)}</b> desvela una mezcla introspectiva de <b>${songGenre}</b> y <b>${songSubgenre}</b>. Deja que el paisaje sonoro evocador resuene dentro de ti y revele nuevas capas de expresión musical.`,
          `¡Embárcate en una aventura sonora con la última oferta de <b>${artistName}</b>, <b>"${songName}"</b>! Desde <b>${this.getCountryName(artistCountry)}</b>, este artista visionario entrelaza de manera fluida los elementos de <b>${songGenre}</b> y <b>${songSubgenre}</b>. Sumérgete en las melodías hipnóticas y deja que la música te transporte a un mundo de pura imaginación.`
        ];
      }

      const randomIndex = Math.floor(Math.random() * randomDescriptions.length);

      return randomDescriptions[randomIndex];
    },
    getCountryName: function (userCountry) {
      let country = _.find(countries, item => item.code === userCountry)

      if (country) {
        return country.name;
      } else {
        return userCountry || 'N/A';
      }
    },
    selectTrack: function (release, skipPlayer) {
      if (release.selected) return;

      _.each(this.music, item => {
        item.selected = false;
      })

      release.selected = true;
      this.selectedItem = release;

      if (!skipPlayer) {
        events.$emit('setPlayer', release);
      }
    },
    renderSlider: function () {
      this.$forceUpdate();

      setTimeout(() => {
        jQuery(this.$refs.releasesBanner).slick({
          slidesToShow: 5,
          speed: 300,
          arrows:false,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          focusOnSelect: true,
          responsive: [{
              breakpoint: 992,
              settings: {
                  arrows: false,
                  slidesToShow: 3
              }
          }, {
              breakpoint: 480,
              settings: {
                  arrows: false,
                  verticalSwiping:false,
                  slidesToShow:4
              }
          }],
      });
      }, 50);
    },
    getReleases: function () {
      Music.getReleases({ limit: 15, orderBy: 'release_date' }).then(response => {
        this.music = response.data.items;
        this.selectTrack(this.music[0], true)
        this.renderSlider();
      })
    }
  },
  created: function () {
    this.getReleases();
  },
  mounted: function () {
  },
  components: {
  }
}
</script>

<style scoped>
.releases-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.release-description {
  font-size: 16px;
  line-height: 34px;
  color: white;
}
</style>
