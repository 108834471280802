<template>
  <div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between align-items-center">
      <div class="iq-header-title">
        <h4 class="card-title">{{ $t('featured_artists') }}</h4>
      </div>
      <div id="feature-song-slick-arrow" class="slick-aerrow-block"></div>
    </div>
    <div class="iq-card-body">
      <ul class="list-unstyled row featured-artists iq-box-hover mb-0">
        <li class="col-lg-2 iq-music-box" v-for="(item, index) in artists" v-bind:key="`featured_song_${index}`">
          <div class="iq-card mb-0">
            <div class="iq-card-body p-0">
              <div class="iq-thumb">
                <div class="iq-music-overlay"></div>
                <router-link :to="`/artist/${item.key}`">
                  <img :src="item.cover_image_small || item.cover_image || defaultImage" class="img-border-radius img-fluid w-100"
                    alt="" />
                </router-link>
                <div class="overlay-music-icon">
                  <router-link :to="`/artist/${item.key}`">
                    <i class="las la-eye"></i>
                  </router-link>
                </div>
              </div>
              <div class="feature-list text-center">
                <h6 class="font-weight-600 mb-0">
                  <router-link :to="`/artist/${item.key}`">
                    {{ item.name }}
                  </router-link>
                </h6>
                <p class="mb-0">
                  <img class="country-flag mr-1" onerror="this.src = '/assets/img/countries/default.png'" :src="`/assets/img/countries/${item.user.country || 'default'}.png`" :alt="getCountryName(item.user.country)" :title="getCountryName(item.user.country)" width="16">
                  <router-link :to="`/genres/${item.main_genre.replace('/', '-')}`">
                    {{ item.main_genre }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Music from '../services/music'
import countries from '../data/countries'
import _ from 'underscore'

export default {
  name: 'TrendingSongs',
  data: function () {
    return {
      defaultImage: '/assets/img/img-placeholder.jpg',
      artists: []
    }
  },
  methods: {
    getCountryName: function (userCountry) {
      let country = _.find(countries, item => item.code === userCountry)

      if (country) {
        return country.name;
      } else {
        return userCountry || 'N/A';
      }
    },
    getFeaturedSongs: function () {
      Music.getFeaturedArtists({ limit: 50 }).then(response => {
        this.artists = response.data.items
        this.initSlider()
      })
    },
    initSlider: function () {
      this.$forceUpdate();

      setTimeout(() => {
        window.jQuery('.featured-artists').slick({
          slidesToShow: 6,
          speed: 300,
          slidesToScroll: 1,
          focusOnSelect: true,
            appendArrows: $('#feature-song-slick-arrow'),
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    autoplay:true,
                    slidesToShow: 1
                }
            }]
        });
      }, 50);
    }
  },
  mounted: function () {
  },
  created: function () {
    this.getFeaturedSongs()
  }
}
</script>

<style scoped>
.country-flag {
  display: inline-block;
}
</style>