<template>
  <div class="iq-card">
    <div class="iq-card-body profile-page">
      <p class="p-3 text-center" v-if="loading">
        <i class="fa fa-spinner fa-spin"></i>
      </p>
      <div class="profile-header" v-if="artist._id && !loading">
        <div class="cover-container text-center">
          <img width="150" :src="artist.cover_image_small || artist.cover_image || defaultImage"
            onerror="this.src = '/assets/img/img-placeholder.jpg'" alt="profile-bg" class="rounded-circle img-fluid">
          <div class="profile-detail mt-3">
            <h3 v-if="!artist.lyrics">{{ artist.name }}</h3>
            <router-link class="h3 text-dark" v-if="artist.lyrics" :to="`/artist/${artist.key}`">{{ artist.name }}</router-link>
            <p class="text-primary">
              <router-link v-if="artist.lyrics" :to="`/lyrics/${artist.main_genre}`">{{ artist.main_genre }}</router-link><router-link v-if="!artist.lyrics" :to="`/artists?genre=${artist.main_genre}`">{{ artist.main_genre }}</router-link> <span class="text-dark">/</span> {{ getCountryName(artist.user.country) }}</p>
            <p>
              {{ generateArtistBio(artist.name, getCountryName(artist.user.country), artist.main_genre, artist.style) }}
            </p>
          </div>
          <div class="iq-social d-inline-block align-items-center">
            <ul class="list-inline d-flex p-0 mb-0 align-items-center">
              <li>
                <a target="_blank" v-if="spotify" :href="spotify" class="avatar-40 rounded-circle bg-primary mr-2 spotify"><i class="fa fa-spotify"
                    aria-hidden="true"></i></a>
              </li>
              <li>
                <a target="_blank" v-if="facebook" :href="facebook" class="avatar-40 rounded-circle bg-primary mr-2 facebook"><i class="fa fa-facebook"
                    aria-hidden="true"></i></a>
              </li>
              <li>
                <a target="_blank" v-if="twitter" :href="twitter" class="avatar-40 rounded-circle bg-primary mr-2 twitter"><i class="fa fa-twitter"
                    aria-hidden="true"></i></a>
              </li>
              <li>
                <a target="_blank" v-if="youtube" :href="youtube" class="avatar-40 rounded-circle bg-primary mr-2 youtube"><i class="fa fa-youtube-play"
                    aria-hidden="true"></i></a>
              </li>
              <li>
                <a target="_blank" v-if="instagram" :href="instagram" class="avatar-40 rounded-circle bg-primary instagram"><i class="fa fa-instagram"
                    aria-hidden="true"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from '../data/countries';
import _ from 'underscore';

export default {
  name: 'ArtistPage',
  props: ['artist'],
  data: function () {
    return {
      loading: true,
      defaultImage: '/assets/img/img-placeholder.jpg',
      facebook: '',
      instagram: '',
      spotify: '',
      twitter: '',
      youtube: ''
    }
  },
  methods: {
    getCountryName: function (userCountry) {
      let country = _.find(countries, item => item.code === userCountry)

      if (country) {
        return country.name;
      } else {
        return userCountry || 'N/A';
      }
    },
    generateArtistBio(artistName, country, mainGenre, style) {
      let bioVariations = [
        `${artistName} is a talented ${country} artist known for their unique blend of ${mainGenre} with a touch of ${style}. With their captivating melodies and heartfelt lyrics, they have captivated audiences worldwide.`,
        `${artistName} hails from ${country} and is a rising star in the ${mainGenre} scene. Their signature style combines elements of ${style} with infectious beats, creating a sound that is both refreshing and irresistible.`,
        `Originating from ${country}, ${artistName} is a force to be reckoned with in the world of ${mainGenre}. Their music is characterized by its innovative fusion of ${style} and a profound lyrical depth that resonates with listeners on a profound level.`,
        `${artistName}, the ${country} sensation, is redefining the ${mainGenre} landscape with their distinctive sound. Their music effortlessly blends elements of ${style} with powerful vocals, creating an immersive and captivating experience.`,
        `${artistName} is an accomplished ${mainGenre} artist from ${country} known for their dynamic performances and genre-defying style. With influences ranging from ${style} to various musical traditions, their music is a true testament to artistic exploration.`,
        `With a vibrant sound that reflects the rich cultural heritage of ${country}, ${artistName} is making waves in the ${mainGenre} scene. Their music effortlessly combines elements of ${style} with infectious rhythms, creating a truly unique sonic experience.`,
        `Hailing from ${country}, ${artistName} is a rising star in the ${mainGenre} realm. Their music showcases a masterful fusion of ${style} and ${mainGenre} elements, resulting in a captivating and innovative sonic tapestry.`,
        `${artistName}, the ${country} prodigy, has taken the ${mainGenre} world by storm with their exceptional talent and distinctive style. Their music embraces elements of ${style} while pushing the boundaries of the ${mainGenre} genre.`,
        `Enigmatic and visionary, ${artistName} has emerged as a leading figure in the ${mainGenre} scene. With their groundbreaking approach that blends ${style} with ${mainGenre}, they continue to push the boundaries of musical innovation.`,
        `Drawing inspiration from their ${country} roots, ${artistName} crafts evocative music that transcends genres. Their unique sound fuses ${mainGenre} with elements of ${style}, resulting in a truly mesmerizing auditory experience.`,
        `${artistName} is an acclaimed ${mainGenre} artist from ${country} known for their genre-bending compositions. With their bold experimentation and incorporation of ${style} influences, they have forged a distinctive sound that sets them apart.`,
      ];

      let bioVariationsWithoutStyle = [
        `${artistName} is a talented artist from ${country} known for their captivating music in the ${mainGenre} genre. With their unique sound and heartfelt lyrics, they have gained a loyal following worldwide.`,
        `Hailing from ${country}, ${artistName} is an emerging artist in the ${mainGenre} scene. Their music resonates with audiences through their innovative approach and a distinct blend of influences.`,
        `${artistName} is an accomplished ${mainGenre} artist from ${country} known for their powerful performances and soulful compositions. Their music transcends genres and connects with listeners on a deep emotional level.`,
        `With a diverse range of influences, ${artistName} brings a fresh perspective to the ${mainGenre} genre. Originating from ${country}, their music is a blend of captivating melodies and thought-provoking lyrics.`,
        `Originating from ${country}, ${artistName} is a rising star in the ${mainGenre} scene. Their music showcases their versatility and ability to connect with audiences through their heartfelt lyrics and infectious melodies.`,
        `${artistName}, the talented artist from ${country}, is making waves in the ${mainGenre} genre. With their dynamic sound and authentic storytelling, they have captured the attention of music enthusiasts globally.`,
        `Embracing their ${country} roots, ${artistName} delivers a unique and compelling sound in the ${mainGenre} genre. Their music reflects their diverse influences and resonates with listeners through its captivating energy.`,
        `With a passion for creating music that pushes boundaries, ${artistName} is a trailblazer in the ${mainGenre} scene. Hailing from ${country}, they infuse their music with innovation and a fearless artistic vision.`,
      ];

      if (this.$i18n.locale === 'es') {
        bioVariations = [
          `${artistName} es un talentoso artista ${country} conocido por su mezcla única de ${mainGenre} con un toque de ${style}. Con sus melodías cautivadoras y letras sinceras, han cautivado al público en todo el mundo.`,
          `${artistName} proviene de ${country} y es una estrella en ascenso en la escena del ${mainGenre}. Su estilo distintivo combina elementos de ${style} con ritmos contagiosos, creando un sonido refrescante e irresistible.`,
          `Originario de ${country}, ${artistName} es una fuerza a tener en cuenta en el mundo del ${mainGenre}. Su música se caracteriza por su fusión innovadora de ${style} y una profundidad lírica que conecta con los oyentes a un nivel profundo.`,
          `${artistName}, la sensación de ${country}, está redefiniendo el panorama del ${mainGenre} con su sonido distintivo. Su música combina sin esfuerzo elementos de ${style} con voces poderosas, creando una experiencia inmersiva y cautivadora.`,
          `${artistName} es un talentoso artista de ${mainGenre} de ${country} conocido por sus actuaciones dinámicas y su estilo que desafía los géneros. Con influencias que van desde ${style} hasta diversas tradiciones musicales, su música es un verdadero testimonio de la exploración artística.`,
          `Con un sonido vibrante que refleja la rica herencia cultural de ${country}, ${artistName} está causando sensación en la escena del ${mainGenre}. Su música combina sin esfuerzo elementos de ${style} con ritmos contagiosos, creando una experiencia sonora verdaderamente única.`,
          `Proveniente de ${country}, ${artistName} es una estrella en ascenso en el mundo del ${mainGenre}. Su música muestra una fusión magistral de ${style} y elementos del ${mainGenre}, lo que resulta en un tapiz sonoro cautivador e innovador.`,
          `${artistName}, el prodigio de ${country}, ha conquistado el mundo del ${mainGenre} con su excepcional talento y estilo distintivo. Su música incorpora elementos de ${style} mientras empuja los límites del género ${mainGenre}.`,
          `Enigmático y visionario, ${artistName} ha surgido como una figura destacada en la escena del ${mainGenre}. Con su enfoque revolucionario que combina ${style} con ${mainGenre}, continúan empujando los límites de la innovación musical.`,
          `Tomando inspiración de sus raíces en ${country}, ${artistName} crea música evocadora que trasciende los géneros. Su sonido único fusiona el ${mainGenre} con elementos de ${style}, resultando en una experiencia auditiva verdaderamente cautivadora.`,
          `${artistName} es un aclamado artista de ${mainGenre} de ${country} conocido por sus composiciones que desafían los géneros. Con su audaz experimentación e incorporación de influencias de ${style}, han forjado un sonido distintivo que los distingue.`,
        ];

        bioVariationsWithoutStyle = [
          `${artistName} es un talentoso artista de ${country} conocido por su música cautivadora en el género ${mainGenre}. Con su sonido único y letras sinceras, han ganado un seguimiento leal en todo el mundo.`,
          `Proveniente de ${country}, ${artistName} es un artista emergente en la escena del ${mainGenre}. Su música conecta con el público a través de su enfoque innovador y una mezcla distintiva de influencias.`,
          `${artistName} es un artista destacado de ${mainGenre} de ${country} conocido por sus actuaciones poderosas y composiciones conmovedoras. Su música trasciende los géneros y conecta con los oyentes a un nivel emocional profundo.`,
          `Con una amplia variedad de influencias, ${artistName} aporta una perspectiva fresca al género ${mainGenre}. Originarios de ${country}, su música es una combinación de melodías cautivadoras y letras reflexivas.`,
          `Proveniente de ${country}, ${artistName} es una estrella en ascenso en la escena del ${mainGenre}. Su música muestra su versatilidad y su capacidad para conectar con el público a través de letras sinceras y melodías contagiosas.`,
          `${artistName}, el talentoso artista de ${country}, está causando sensación en el género ${mainGenre}. Con su sonido dinámico y su narración auténtica, han captado la atención de los entusiastas de la música a nivel mundial.`,
          `Abrazando sus raíces en ${country}, ${artistName} ofrece un sonido único y cautivador en el género ${mainGenre}. Su música refleja sus diversas influencias y resuena con los oyentes a través de su energía cautivadora.`,
          `Con una pasión por crear música que desafía los límites, ${artistName} es un pionero en la escena del ${mainGenre}. Proveniente de ${country}, infunden su música con innovación y una visión artística audaz.`,
        ];
      }

      if (style) {
        const randomIndex = Math.floor(Math.random() * bioVariations.length);
        return bioVariations[randomIndex];
      } else {
        const randomIndex = Math.floor(Math.random() * bioVariationsWithoutStyle.length);
        return bioVariationsWithoutStyle[randomIndex];
      }
    }
  },
  created: function () {
    if (this.artist.facebook && !this.artist.facebook.match(/^http/)) {
        this.facebook = 'https://facebook.com/' + this.artist.facebook;
    } else if (this.artist.facebook && this.artist.facebook.match(/^http/)) {
      this.facebook = this.artist.facebook
    }

    if (this.artist.instagram && !this.artist.instagram.match(/^http/)) {
      this.instagram = 'https://instagram.com/' + this.artist.instagram;
    } else if (this.artist.instagram && this.artist.instagram.match(/^http/)) {
      this.instagram = this.artist.instagram
    }

    if (this.artist.twitter && !this.artist.twitter.match(/^http/)) {
      this.twitter = 'https://twitter.com/' + this.artist.twitter;
    } else if (this.artist.twitter && this.artist.twitter.match(/^http/)) {
      this.twitter = this.artist.twitter
    }

    if (this.artist.youtube && !this.artist.youtube.match(/^http/)) {
      this.youtube = 'https://youtube.com/' + this.artist.youtube;
    } else if (this.artist.youtube && this.artist.youtube.match(/^http/)) {
      this.youtube = this.artist.youtube
    }

    this.loading = false;
  }
}
</script>