<template>
  <div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between align-items-center">
      <div class="iq-header-title">
        <h4 class="card-title">{{ $t('featured_releases') }}</h4>
      </div>
      <div id="feature-album-slick-arrow" class="slick-aerrow-block"></div>
    </div>
    <div class="iq-card-body">
      <ul class="list-unstyled row feature-album iq-box-hover mb-0">
        <li class="col-lg-2 iq-music-box" v-for="(item, index) in music" v-bind:key="`featured_album_${index}`">
          <div class="iq-card mb-0">
            <div class="iq-card-body p-0">
              <div class="iq-thumb">
                <div class="iq-music-overlay"></div>
                <router-link :to="`/artist/${item.artist.key}`">
                  <img :src="item.cover_image_small || item.cover_image || defaultImage" class="img-border-radius img-fluid w-100"
                    alt="" />
                </router-link>
                <div class="overlay-music-icon">
                  <a href="javascript:void(0)" @click="selectRelease(item)">
                    <i class="las la-play-circle"></i>
                  </a>
                </div>
              </div>
              <div class="feature-list text-center">
                <h6 class="font-weight-600 mb-0">
                  <router-link :to="`/releases/${item.key}`">
                    {{ item.title }}
                  </router-link>
                </h6>
                <p class="mb-0">
                  <router-link :to="`/artist/${item.artist.key}`">
                    {{ item.artist.name }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Music from '../services/music'
import _ from 'underscore'
import events from '../utils/events'

export default {
  name: 'FeaturedAlbums',
  data: function () {
    return {
      defaultImage: '/assets/img/img-placeholder.jpg',
      music: []
    }
  },
  methods: {
    selectRelease: function (release) {
      if (release.selected) return;

      _.each(this.music, item => {
        item.selected = false;
      })

      release.selected = true;
      this.selectedItem = release;

      events.$emit('setPlayer', release);
    },
    getFeaturedAlbums: function () {
      Music.getReleases({ limit: 15, orderBy: 'release_date', featured: 1 }).then(response => {
        this.music = response.data.items
        this.initSlider()
      })
    },
    initSlider: function () {
      this.$forceUpdate();

      setTimeout(() => {
        window.jQuery('.feature-album').slick({
          slidesToShow: 6,
          speed: 300,
          slidesToScroll: 1,
          focusOnSelect: true,
            appendArrows: $('#feature-album-slick-arrow'),
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    autoplay:true,
                    slidesToShow: 1
                }
            }]
        });
      }, 50);
    }
  },
  mounted: function () {
  },
  created: function () {
    this.getFeaturedAlbums()
  }
}
</script>

<style scoped>
</style>